import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Container, Typography, Box, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SubdomainForm({ selectedFolder }) {
  const [subdomain, setSubdomain] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subdomains, setSubdomains] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    console.log('Fetching subdomains for folder:', selectedFolder);
    if (selectedFolder) {
      const fetchSubdomains = async () => {
        try {
          const response = await axios.get(`/${selectedFolder}/subdomains/subdomains.txt`);
          const data = response.data.split('\n').filter(line => line.trim() !== '').map((line, index) => ({ id: index, subdomain: line }));
          setSubdomains(data);
        } catch (error) {
          console.error(error);
          setSubdomains([]);
        }
      };
      fetchSubdomains();
    }
  }, [selectedFolder]);

  const handleSubmitSubdomain = async (e) => {
    e.preventDefault();
    setLoading(true);
    toast.info('Enumeration started');
    const formData = new FormData();
    formData.append('domain', subdomain);
    formData.append('folder', selectedFolder);

    try {
      const response = await axios.post('/subdomains/enumerate', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSubdomains(response?.data?.subdomains || []); // Update the subdomains state
      toast.success(response?.data?.message || 'Enumeration completed', { autoClose: false });
      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.error || 'Error');
      setLoading(false);
    }
  };

  const handleSubmitFile = async (e) => {
    e.preventDefault();
    setLoading(true);
    toast.info('Enumeration started');

    const formData = new FormData();
    formData.append('file', file);
    formData.append('folder_name', selectedFolder);

    try {
      const response = await axios.post('/subdomains/enumerate-list', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSubdomains(response?.data?.subdomains || []);
      toast.success(response?.data?.message || 'Enumeration completed', { autoClose: false });
      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.error || 'Error');
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const filteredRows = subdomains.filter((row) =>
    row.subdomain && row.subdomain.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const columns = [
    { field: 'subdomain', headerName: 'Subdomain', width: 300 }
  ];

  return (
    <Container maxWidth="xl">
      <Typography variant="h5" gutterBottom align="center">
        Enumerate Subdomains
      </Typography>
      <Box component="form" onSubmit={handleSubmitSubdomain} sx={{ mt: 2 }}>
        <TextField
          label="Subdomain"
          value={subdomain}
          onChange={(e) => setSubdomain(e.target.value)}
          fullWidth
          required
          sx={{ mb: 2 }}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          {loading ? <CircularProgress size={24} /> : 'Submit Subdomain'}
        </Button>
      </Box>
      <Box component="form" onSubmit={handleSubmitFile} sx={{ mt: 2 }}>
        <Button
          variant="contained"
          component="label"
          fullWidth
          sx={{ mt: 2, mb: 2 }}
        >
          Choose File
          <input
            type="file"
            hidden
            onChange={handleFileChange}
          />
        </Button>
        {file && (
          <Typography variant="body1" gutterBottom>
            {file.name}
          </Typography>
        )}
        <Button type="submit" variant="contained" color="primary" fullWidth>
          {loading ? <CircularProgress size={24} /> : 'Submit File'}
        </Button>
      </Box>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <DataGrid
        rows={filteredRows}
        columns={columns}
        disableSelectionOnClick
        autoHeight
      />
      <ToastContainer position="bottom-right" />
    </Container>
  );
}

export default SubdomainForm;
