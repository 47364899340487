import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { Container, Typography, TextField } from '@mui/material';
import ProbeForm from './ProbeForm';

const ProbeResults = ({ selectedFolder }) => {
  const initialColumns = [
    { field: 'timestamp', headerName: 'Timestamp', flex: 1 },
    { field: 'port', headerName: 'Port', flex: 1 },
    { field: 'url', headerName: 'URL', flex: 2 },
    { field: 'input', headerName: 'Input', flex: 2 },
    { field: 'title', headerName: 'Title', flex: 2 },
    { field: 'scheme', headerName: 'Scheme', flex: 1 },
    { field: 'webserver', headerName: 'Webserver', flex: 2 },
    { field: 'content_type', headerName: 'Content Type', flex: 2 },
    { field: 'method', headerName: 'Method', flex: 1 },
    { field: 'host', headerName: 'Host', flex: 2 },
    { field: 'path', headerName: 'Path', flex: 2 },
    { field: 'status_code', headerName: 'Status Code', flex: 1 },
    { field: 'content_length', headerName: 'Content Length', flex: 1 },
    { field: 'time', headerName: 'Time', flex: 1 },
    { field: 'failed', headerName: 'Failed', flex: 1 },
    { field: 'a', headerName: 'A', flex: 2 },
    { field: 'cname', headerName: 'CNAME', flex: 2 },
    { field: 'tech', headerName: 'Tech', flex: 2 },
    { field: 'resolvers', headerName: 'Resolvers', flex: 2 }
  ];

  const [probedSubdomains, setProbedSubdomains] = useState([]);
  const [columns, setColumns] = useState(initialColumns);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [searchQuery, setSearchQuery] = useState('');

  const handleColumnVisibilityChange = useCallback(
    (newModel) => {
      setColumnVisibilityModel(newModel);
      localStorage.setItem('probeResultsColumnVisibilityModel', JSON.stringify(newModel));
    },
    []
  );

  useEffect(() => {
    const savedColumnVisibilityModel = JSON.parse(localStorage.getItem('probeResultsColumnVisibilityModel'));
    if (savedColumnVisibilityModel) {
      setColumnVisibilityModel(savedColumnVisibilityModel);
    }
  }, []);

  useEffect(() => {
    if (selectedFolder) {
      const fetchProbedSubdomains = async () => {
        try {
          const response = await axios.get(`/${selectedFolder}/subdomains/httpx.json`);
          const data = response.data.split('\n').filter(line => line.trim() !== '').map((line, index) => ({ id: index, ...JSON.parse(line) }));
          setProbedSubdomains(data);
        } catch (error) {
          console.error(error);
          setProbedSubdomains([]);
        }
      };

      fetchProbedSubdomains();
    }
  }, [selectedFolder]);

  const filteredRows = probedSubdomains.filter((row) =>
    columns.some(
      (column) =>
        row[column.field] &&
        row[column.field].toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <Container maxWidth="xl">
      <ProbeForm setProbedSubdomains={setProbedSubdomains} selectedFolder={selectedFolder} />
      <Typography variant="h5" gutterBottom align="center">
        Probe Results
      </Typography>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      <div className="data-grid-wrapper">
        <DataGrid
          className="data-grid"
          rows={filteredRows}
          columns={columns}
          checkboxSelection
          disableSelectionOnClick
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={handleColumnVisibilityChange}
          autoHeight
        />
      </div>
    </Container>
  );
};

export default ProbeResults;
