import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import axios from 'axios';

function FolderSelector({ folders, selectedFolder, setSelectedFolder, setFolders }) {
  const [open, setOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');

  const handleChange = (event) => {
    setSelectedFolder(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewFolderName('');
  };

  const handleCreateFolder = async () => {
    try {
      const response = await axios.post('/folders/create', { folder_name: newFolderName });
      if (response.status === 201) {
        setFolders([...folders, newFolderName]);
        setSelectedFolder(newFolderName);
      }
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel>Folder</InputLabel>
        <Select
          value={selectedFolder}
          onChange={handleChange}
          label="Folder"
        >
          {folders.map((folder) => (
            <MenuItem key={folder} value={folder}>
              {folder}
            </MenuItem>
          ))}
        </Select>
        <Button onClick={handleClickOpen} sx={{ mt: 2 }}>
          Create New Folder
        </Button>
      </FormControl>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create New Folder</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Folder Name"
            fullWidth
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleCreateFolder}>Create</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default FolderSelector;
