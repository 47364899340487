import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Container, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PortScanForm = ({ selectedFolder }) => {
  const [loading, setLoading] = useState(false);
  const [scannedPorts, setScannedPorts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [columns, setColumns] = useState([
    { field: 'port', headerName: 'Port', width: 300 }
  ]);

  useEffect(() => {
    const savedColumns = JSON.parse(localStorage.getItem('portscanColumns'));
    if (savedColumns) {
      setColumns(savedColumns);
    }
  }, []);

  useEffect(() => {
    if (selectedFolder) {
      const fetchScannedPorts = async () => {
        try {
          const response = await axios.get(`/${selectedFolder}/subdomains/naabu.txt`);
          const data = response.data.split('\n').filter(line => line.trim() !== '').map((line, index) => ({ id: index, port: line }));
          setScannedPorts(data);
        } catch (error) {
          console.error(error);
          setScannedPorts([]);
        }
      };
      fetchScannedPorts();
    }
  }, [selectedFolder]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    toast.info('Port scan started');

    try {
      const formData = new FormData();
      formData.append('folder', selectedFolder);

      const response = await axios.post('/subdomains/portscan', formData);
      const data = response.data.ports.split('\n').filter(line => line.trim() !== '').map((line, index) => ({ id: index, port: line }));
      setScannedPorts(data);
      toast.success('Port scan completed', { autoClose: false });
      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.error || 'Error');
      setLoading(false);
    }
  };

  const filteredRows = scannedPorts.filter((row) =>
    row.port && row.port.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleColumnChange = (newColumns) => {
    setColumns(newColumns);
    localStorage.setItem('portscanColumns', JSON.stringify(newColumns));
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h5" gutterBottom align="center">
        Scanned Ports
      </Typography>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <TextField
          label="Folder"
          value={selectedFolder}
          fullWidth
          disabled
          required
          sx={{ mb: 2 }}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          {loading ? 'Loading...' : 'Start Port Scan'}
        </Button>
      </Box>
      <DataGrid
        rows={filteredRows}
        columns={columns}
        checkboxSelection
        disableSelectionOnClick
        onColumnOrderChange={handleColumnChange}
        autoHeight
      />
      <ToastContainer position="bottom-right" />
    </Container>
  );
};

export default PortScanForm;
