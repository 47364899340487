import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Box, CssBaseline, Toolbar, Container } from '@mui/material';
import axios from 'axios';
import SubdomainForm from './components/SubdomainForm';
import ResolveForm from './components/ResolveForm';
import PermuteForm from './components/PermuteForm';
import ProbeForm from './components/ProbeForm';
import PortScanForm from './components/PortScanForm';
import ProbeResults from './components/ProbeResults';
import SideMenu from './components/SideMenu';
import FolderSelector from './components/FolderSelector';
import ThemeToggleButton from './ThemeToggleButton';
import { CustomThemeProvider } from './ThemeContext';

function App() {
  const [selectedFolder, setSelectedFolder] = useState('');
  const [folders, setFolders] = useState([]);

  useEffect(() => {
    const fetchFolders = async () => {
      const response = await axios.get('/folders');
      setFolders(response.data.folders);
    };

    fetchFolders();
  }, []);

  return (
    <CustomThemeProvider>
      <Router>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <SideMenu />
          <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
            <Toolbar />
            <Box display="flex" justifyContent="flex-end" mb={2}>
              <ThemeToggleButton />
            </Box>
            <Container maxWidth="xl">
              <FolderSelector
                folders={folders}
                selectedFolder={selectedFolder}
                setSelectedFolder={setSelectedFolder}
                setFolders={setFolders}
              />
              <Routes>
                <Route path="/" element={<div />} />
                <Route path="/enumeration" element={<SubdomainForm selectedFolder={selectedFolder} />} />
                <Route path="/resolve" element={<ResolveForm selectedFolder={selectedFolder} />} />
                <Route path="/permute" element={<PermuteForm selectedFolder={selectedFolder} />} />
                <Route path="/probe" element={<ProbeResults selectedFolder={selectedFolder} />} />
                <Route path="/portscan" element={<PortScanForm selectedFolder={selectedFolder} />} />
              </Routes>
            </Container>
          </Box>
        </Box>
      </Router>
    </CustomThemeProvider>
  );
}

export default App;
