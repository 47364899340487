import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse, Toolbar } from '@mui/material'; // Adicionei o import do Toolbar
import {
  ExpandLess,
  ExpandMore,
  SubdirectoryArrowRight as SubdirectoryArrowRightIcon,
  Dns as DnsIcon,
  Wifi as WifiIcon,
  PermDeviceInformation as PermDeviceInformationIcon,
  AccountTree as AccountTreeIcon,
  Lan as LanIcon
} from '@mui/icons-material';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

const SideMenu = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        marginTop: '64px' // altura do AppBar
      }}
    >
      <Toolbar />
      <List>
        <ListItem button onClick={handleClick}>
          <ListItemIcon>
            <DnsIcon />
          </ListItemIcon>
          <ListItemText primary="Subdomains" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={Link} to="/enumeration">
              <ListItemIcon>
                <SubdirectoryArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="Enumeration" />
            </ListItem>
            <ListItem button component={Link} to="/resolve">
              <ListItemIcon>
                <WifiIcon />
              </ListItemIcon>
              <ListItemText primary="Resolution" />
            </ListItem>
            <ListItem button component={Link} to="/permute">
              <ListItemIcon>
                <PermDeviceInformationIcon />
              </ListItemIcon>
              <ListItemText primary="Permutation" />
            </ListItem>
            <ListItem button component={Link} to="/portscan">
              <ListItemIcon>
                <LanIcon />
              </ListItemIcon>
              <ListItemText primary="Port Scanning" />
            </ListItem>
            <ListItem button component={Link} to="/probe">
              <ListItemIcon>
                <AccountTreeIcon />
              </ListItemIcon>
              <ListItemText primary="Probing" />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </Drawer>
  );
};

export default SideMenu;
