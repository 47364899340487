import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Container, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PermuteForm = ({ selectedFolder }) => {
  const [loading, setLoading] = useState(false);
  const [permutedSubdomains, setPermutedSubdomains] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [columns, setColumns] = useState([
    { field: 'subdomain', headerName: 'Subdomain', width: 300 }
  ]);

  useEffect(() => {
    const savedColumns = JSON.parse(localStorage.getItem('permuteColumns'));
    if (savedColumns) {
      setColumns(savedColumns);
    }
  }, []);

  useEffect(() => {
    if (selectedFolder) {
      const fetchPermutedSubdomains = async () => {
        try {
          const response = await axios.get(`/${selectedFolder}/subdomains/subdomains.txt`);
          const data = response.data.split('\n').filter(line => line.trim() !== '').map((line, index) => ({ id: index, subdomain: line }));
          setPermutedSubdomains(data);
        } catch (error) {
          console.error(error);
          setPermutedSubdomains([]);
        }
      };
      fetchPermutedSubdomains();
    }
  }, [selectedFolder]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    toast.info('Permutation started');

    try {
      const formData = new FormData();
      formData.append('folder', selectedFolder);

      const response = await axios.post('/subdomains/permute', formData);
      const data = response.data.permute.split('\n').filter(line => line.trim() !== '').map((line, index) => ({ id: index, subdomain: line }));
      setPermutedSubdomains(data);
      toast.success('Permutation completed', { autoClose: false });
      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.error || 'Error');
      setLoading(false);
    }
  };

  const filteredRows = permutedSubdomains.filter((row) =>
    row.subdomain && row.subdomain.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleColumnChange = (newColumns) => {
    setColumns(newColumns);
    localStorage.setItem('permuteColumns', JSON.stringify(newColumns));
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h5" gutterBottom align="center">
        Permuted Subdomains
      </Typography>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <TextField
          label="Folder"
          value={selectedFolder}
          fullWidth
          disabled
          required
          sx={{ mb: 2 }}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          {loading ? 'Loading...' : 'Start Permutation'}
        </Button>
      </Box>
      <DataGrid
        rows={filteredRows}
        columns={columns}
        checkboxSelection
        disableSelectionOnClick
        onColumnOrderChange={handleColumnChange}
        autoHeight
      />
      <ToastContainer position="bottom-right" />
    </Container>
  );
};

export default PermuteForm;
